import * as React from 'react';
import Layout from '../components/layout/layout';

import fourOhFour from '../images/404.svg';

function NotFoundPage({ location }) {
  const headerContent = {
    heading: "Sorry, this page isn't available (404)",
    subheading:
      'The link you followed may be broken, or the page may have been removed.',
    image: fourOhFour,
    alt: 'Nothing to see here',
  };

  return (
    <Layout
      title="404 | Page Not Found"
      headerContent={headerContent}
      location={location}
    >
      <div className="mb-[120px]" />
    </Layout>
  );
}

export default NotFoundPage;
